import MyLike from "./like.png"
import MyBag from "./bag.png"
import MyCalendar from "./calendar.png"
import MyFile from "./file.png"
import MyNoise from "./noise.png"
import MyMesh from './mesh.svg'
import MyMesh3 from './mesh3.png'
import MyImg1 from './img1.png'
import MyImg2 from './img2.png'
import MyImg3 from './img3.png'
import MyImg4 from './img4.png'
import MyBag2 from './bag.png'
import MyLike2 from './like.png'
import MyCalendar2 from "./calendar.png"
import MyFaqImg from "./faqImg.png"
import MyUser from "./user.svg"
import VideoPause from "./Pause_video.svg"
import VideoReplay from "./Replay.svg"
import VideoVolume from "./Volume.svg"
import VideoMute from "./Mute.svg"
import VideoPlay from "./Play.svg"
import VideoFull from "./fullscreen.svg"
import User1 from "./avatar.jpg"
import User2 from "./avatar1.png"
import User3 from "./avatar2.jfif"
import User4 from "./avatar3.png"




export const Avatar1 = () => {
    return(
       <img src={User1} className="avatar"  alt="avatar" />
    
    )
}
export const Avatar2 = () => {
    return(
       <img src={User2} className="avatar"  alt="avatar" />
    
    )
}
export const Avatar3 = () => {
    return(
       <img src={User3} className="avatar"  alt="avatar" />
    
    )
}
export const Avatar4 = () => {
    return(
       <img src={User4} className="avatar"  alt="avatar" />
    
    )
}
export const Fulll = () => {
    return(
       <img src={VideoFull} className="full"  alt="full" />
    
    )
}
export const Play = () => {
    return(
       <img src={VideoPlay} className="play"  alt="play" />
    
    )
}
export const Pause = () => {
    return(
       <img src={VideoPause} className="pause"  alt="pause" />
    
    )
}
export const Replay = () => {
    return(
       <img src={VideoReplay} className="replay"  alt="replay" />
    
    )
}
export const Volume = () => {
    return(
       <img src={VideoVolume} className="volume"  alt="volume" />
    
    )
}
export const Mute = () => {
    return(
       <img src={VideoMute} className="mute"  alt="mute" />
    
    )
}
export const User = () => {
    return(
       <img src={MyUser} className="user"  alt="user" />
    
    )
}
export const Bag = () => {
    return(
       <img src={MyBag} className="bag"  alt="bag"/>
    
    )
}
export const Like = () => {
    return(
       <img src={MyLike} className="like"  alt="like"/>
    
    )
}
export const Calendar = () => {
    return(
       <img src={MyCalendar} className="calendar" alt="calendar" />
    
    )
}
export const File = () => {
    return(
       <img src={MyFile} className="file" alt="file" />
    
    )
}

export const Noise = () => {
    return(
       <img src={MyNoise} className="noise" alt="noise" />
    
    )
}

export const Mesh = () => {
    return(
       <img src={MyMesh} className="meshImg" alt="mesh" />


    )
}

export const Mesh3 = () => {
    return(
       <img src={MyMesh3} className="mesh3"  alt="mesh3"/>


    )
}

export const Img1 = () => {
    return(
       <img src={MyImg1} alt="image1" />
    
    )
}
export const Img2 = () => {
    return(
       <img src={MyImg2} alt="image2" />
    
    )
}

export const Img3 = () => {
    return(
       <img src={MyImg3} alt="image3" />
    
    )
}

export const Img4 = () => {
    return(
       <img src={MyImg4} alt="image4" />
    
    )
}

export const Bag2 = () => {
    return(
       <img src={MyBag2}  className="bag2" alt="bag2"/>
    
    )
}

export const Like2 = () => {
    return(
       <img src={MyLike2} className="like2" alt="like2" />
    
    )
}

export const Calendar2 = () => {
    return(
       <img src={MyCalendar2} className="calendar2" alt="calendar2" />
    
    )
}

export const FaqImg = () => {
    return(
       <img src={MyFaqImg} className="faqImg" alt="faq"/>
    
    )
}

export const Logo = () => {
    return(
        <svg width="132" height="40" viewBox="0 0 132 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.09387 35.0489C3.52099 36.5657 3.94263 38.0825 4.36975 39.6048H0.772098C0.670705 39.6048 0.570304 39.5848 0.476629 39.546C0.382954 39.5072 0.297838 39.4504 0.226142 39.3787C0.0813459 39.2339 0 39.0375 0 38.8327V23.9712C1.03677 27.6546 2.06805 31.3472 3.09387 35.0489Z" fill="#FF001F"/>
        <path d="M13.3283 20.5378C10.4863 17.8327 7.66075 15.1386 4.84067 12.4225C4.72059 12.2632 4.54347 12.1564 4.34644 12.1248C4.14941 12.0932 3.94778 12.139 3.78383 12.2528C2.52985 12.8716 1.26493 13.4903 2.39402e-10 14.0872V1.54198C-5.09009e-06 1.3367 0.0811656 1.13974 0.225807 0.994077C0.370448 0.84841 0.566823 0.76585 0.772098 0.764404H27.6586L25.9666 1.58031C23.4751 2.77405 20.9945 3.98422 18.492 5.15058C18.3692 5.20381 18.2534 5.27185 18.147 5.35319L17.9718 5.4408C17.7485 5.53106 17.5543 5.68094 17.4104 5.87408C17.2665 6.06722 17.1785 6.29619 17.1559 6.53597C16.2086 11.1576 15.2339 15.7792 14.2646 20.4009C14.256 20.6846 14.1667 20.96 14.0073 21.1949L13.3283 20.5378Z" fill="#FF001F"/>
        <path d="M38.8075 1.54198V15.2317C38.3202 13.5889 37.8492 11.9461 37.4331 10.3034C37.2578 9.59699 37.0443 9.53675 36.4255 9.8434C33.8245 11.1521 31.1851 12.3787 28.5567 13.6546C28.0584 13.901 27.8612 13.8572 27.7298 13.2768C27.467 12.1487 27.1822 11.0262 26.788 9.92554C26.6127 9.37795 26.788 9.21368 27.2425 9.00559C30.0242 7.69138 32.7895 6.34432 35.5713 5.03011C35.9655 4.84393 36.086 4.6687 35.9546 4.2142C35.6151 3.06792 35.2847 1.91799 34.9634 0.764404H38.0299C38.132 0.764404 38.2332 0.784517 38.3275 0.823594C38.4218 0.86267 38.5076 0.919946 38.5798 0.99215C38.652 1.06435 38.7092 1.15007 38.7483 1.24441C38.7874 1.33875 38.8075 1.43987 38.8075 1.54198Z" fill="#FF001F"/>
        <path d="M38.6323 16.9072C38.6928 16.8824 38.7514 16.8531 38.8075 16.8196V38.8052C38.8075 38.9071 38.7873 39.0079 38.7482 39.1019C38.7091 39.196 38.6517 39.2813 38.5794 39.3531C38.5071 39.4249 38.4214 39.4816 38.3271 39.5201C38.2328 39.5586 38.1318 39.578 38.0299 39.5773H12.129C11.1872 36.2918 10.2618 32.946 9.33086 29.6331C8.93113 28.2094 8.53139 26.7911 8.1207 25.3127C8.38644 25.3823 8.62147 25.5383 8.78875 25.7562L17.1121 33.1924C17.8404 33.8441 18.3168 33.6743 18.5303 32.6996C19.4393 28.5087 20.352 24.3215 21.2683 20.138C21.334 19.8258 21.4106 19.5192 21.5311 19.0099C21.6571 19.3385 21.7118 19.4425 21.7501 19.5575C23.0644 24.2339 24.384 28.9103 25.6763 33.5922C25.8187 34.1398 25.972 34.1836 26.4484 33.9536C28.3394 33.019 30.2396 32.1064 32.1488 31.2156C32.2745 31.1654 32.3856 31.0844 32.4719 30.9802C32.6362 30.8269 32.6362 30.6297 32.5486 30.3286C31.7327 27.4921 30.966 24.6446 30.1392 21.8191C29.9694 21.2715 30.0735 21.0141 30.5937 20.7732C33.2659 19.4973 35.9436 18.1776 38.6323 16.9072Z" fill="#FF001F"/>
        <path d="M96.1618 38.663C95.4656 39.241 94.6352 39.6346 93.747 39.8075C91.9016 40.1251 90.1822 39.9115 88.7858 38.5042C87.8469 37.5429 87.3002 36.266 87.2526 34.923C87.0719 32.1303 87.8713 29.6552 89.9905 27.7332C90.7888 26.9663 91.7742 26.4222 92.8484 26.1551C93.9226 25.888 95.0481 25.9072 96.1125 26.2109C96.9368 26.4563 97.6434 26.9936 98.1003 27.7222C98.1824 27.3225 98.2591 27.0104 98.2919 26.6928C98.3248 26.3752 98.4562 26.3423 98.7136 26.3423C99.9511 26.3423 101.189 26.3423 102.426 26.3423C102.727 26.3423 102.771 26.4354 102.716 26.7202C101.986 30.8928 101.256 35.0672 100.526 39.2435C100.477 39.5227 100.367 39.5775 100.115 39.5775C98.9107 39.5775 97.7005 39.5775 96.4958 39.5775C96.0797 39.5775 96.0468 39.4242 96.118 39.1011C96.1797 38.9637 96.195 38.8099 96.1618 38.663ZM91.6935 33.5595C91.6743 33.8816 91.7279 34.2039 91.8504 34.5024C91.9729 34.8009 92.1611 35.068 92.401 35.2837C92.641 35.4995 92.9265 35.6583 93.2363 35.7485C93.5461 35.8387 93.8723 35.8579 94.1905 35.8046C95.0561 35.732 95.8603 35.3282 96.4356 34.6774C97.0109 34.0266 97.313 33.179 97.2789 32.311C97.2929 32.033 97.251 31.7549 97.1559 31.4933C97.0607 31.2316 96.9142 30.9917 96.7249 30.7876C96.5355 30.5835 96.3073 30.4193 96.0535 30.3048C95.7998 30.1902 95.5257 30.1276 95.2473 30.1207C94.7807 30.0818 94.3111 30.1432 93.87 30.3007C93.429 30.4582 93.0267 30.7081 92.6902 31.0338C92.3537 31.3594 92.0906 31.7533 91.9187 32.1889C91.7468 32.6245 91.67 33.0918 91.6935 33.5595Z" fill="white"/>
        <path d="M53.3295 21.0745H58.5973C58.8547 21.0745 59.0408 21.0745 58.9751 21.4359C58.7397 22.7227 58.5097 24.015 58.3016 25.3073C58.2523 25.603 58.1373 25.6687 57.858 25.6687C55.9196 25.6687 53.9811 25.6687 52.0372 25.6687C51.7305 25.6687 51.5772 25.718 51.5334 26.0739C51.4403 26.7968 51.3089 27.5196 51.1501 28.2314C51.068 28.5983 51.1501 28.675 51.517 28.675C53.3514 28.675 55.1858 28.675 56.9929 28.675C57.4255 28.675 57.5404 28.7626 57.4419 29.2226C57.19 30.4492 56.9874 31.6812 56.7957 32.9188C56.7464 33.2419 56.6205 33.2966 56.3303 33.2966C54.4466 33.2966 52.5684 33.2966 50.6901 33.2966C50.3232 33.2966 50.2028 33.4007 50.1425 33.7675C49.8414 35.602 49.4964 37.4309 49.1897 39.2434C49.1405 39.5172 49.0638 39.6267 48.7681 39.6212C47.421 39.6212 46.0794 39.6212 44.7324 39.6212C44.3874 39.6212 44.371 39.4953 44.4203 39.216C45.4607 33.3313 46.492 27.4447 47.5141 21.5563C47.5798 21.2004 47.7167 21.1018 48.0617 21.1073C49.8468 21.0854 51.5882 21.0745 53.3295 21.0745Z" fill="white"/>
        <path d="M67.2327 34.5725H63.1532C62.7042 34.5725 62.6659 34.6327 62.8849 35.0324C63.3996 35.9962 64.3141 36.1276 65.2724 36.1276C66.12 36.1571 66.9492 35.8768 67.6051 35.3391C67.7858 35.1803 67.9063 35.2241 68.0651 35.3719C68.8317 36.0893 69.6038 36.8066 70.3978 37.5021C70.6333 37.7156 70.6114 37.8197 70.3978 38.0168C69.5667 38.7105 68.6067 39.233 67.573 39.5544C66.5393 39.8758 65.4522 39.9897 64.3743 39.8895C63.0696 39.8672 61.7993 39.4678 60.7165 38.7396C60.0471 38.2785 59.4917 37.671 59.0922 36.9632C58.6928 36.2554 58.4598 35.4659 58.4111 34.6546C58.2836 33.3862 58.4647 32.1058 58.9388 30.9225C59.413 29.7392 60.1663 28.6881 61.1344 27.8587C62.1025 27.0294 63.2567 26.4464 64.4988 26.1595C65.7409 25.8725 67.0339 25.8901 68.2677 26.2108C71.0713 26.9227 72.6922 29.5511 72.276 32.5081C72.2066 33.0512 72.0931 33.5878 71.9365 34.1125C71.9123 34.25 71.8365 34.3731 71.7245 34.4566C71.6125 34.5401 71.4729 34.5776 71.3342 34.5615C69.9488 34.5615 68.5908 34.5725 67.2327 34.5725ZM65.7269 31.5005C66.4114 31.5005 67.1013 31.5005 67.7858 31.5005C68.1472 31.5005 68.1965 31.38 68.1089 31.0624C68.0394 30.76 67.8892 30.482 67.6741 30.2583C67.459 30.0345 67.1873 29.8734 66.8878 29.792C66.2452 29.6356 65.5698 29.684 64.9561 29.9304C64.3425 30.1769 63.8212 30.6091 63.4653 31.1665C63.323 31.4074 63.323 31.506 63.6461 31.5005C64.3196 31.4896 65.0205 31.5005 65.7269 31.5005Z" fill="white"/>
        <path d="M109.851 25.9807C111.609 26.0081 113.137 26.4352 114.353 27.7494C114.605 28.0365 114.822 28.3527 114.999 28.6913C115.092 28.8665 115.081 28.9541 114.889 29.0417C113.794 29.5619 112.699 30.0876 111.604 30.6297C111.308 30.7776 111.27 30.5585 111.177 30.3997C110.996 30.0689 110.709 29.8082 110.363 29.6593C110.016 29.5105 109.63 29.4819 109.266 29.5784C109.092 29.6122 108.933 29.7005 108.813 29.8304C108.693 29.9604 108.618 30.1253 108.597 30.3012C108.57 30.6681 108.849 30.8488 109.145 30.9692C109.829 31.2279 110.53 31.4401 111.242 31.6044C111.724 31.7479 112.193 31.9311 112.644 32.152C113.239 32.4501 113.725 32.9279 114.032 33.5177C114.34 34.1075 114.454 34.7793 114.358 35.4375C114.134 38.0386 112.392 39.3911 110.092 39.8182C108.408 40.1703 106.657 40.0082 105.066 39.3528C103.996 38.8751 103.139 38.0218 102.656 36.9544C102.552 36.7408 102.612 36.6696 102.799 36.582C103.894 36.0344 105.027 35.5142 106.128 34.9392C106.407 34.7969 106.478 34.9392 106.599 35.1364C106.834 35.5774 107.209 35.9276 107.665 36.1318C108.121 36.3361 108.632 36.3827 109.118 36.2644C109.457 36.1877 109.808 36.0727 109.846 35.673C109.884 35.2733 109.55 35.1254 109.249 35.0214C108.521 34.7585 107.776 34.5395 107.059 34.2876C106.431 34.0933 105.841 33.7953 105.312 33.406C104.129 32.508 103.762 30.9747 104.348 29.2936C105.011 27.399 106.539 26.5557 108.351 26.1395C108.846 26.0451 109.348 25.992 109.851 25.9807Z" fill="white"/>
        <path d="M82.0614 39.5718C81.4646 39.5718 80.8677 39.5445 80.2763 39.5718C79.8766 39.5718 79.8546 39.424 79.9094 39.1173C80.2745 36.9854 80.6487 34.8535 81.032 32.7215C81.7219 28.7899 82.4137 24.8563 83.1073 20.921C83.2223 20.2639 83.2114 20.2639 83.8904 20.2639C84.9855 20.2639 86.0807 20.2639 87.143 20.2639C87.5318 20.2639 87.6468 20.357 87.5756 20.7513C87.1595 23.0621 86.7652 25.3784 86.36 27.6892C85.7029 31.4274 85.0531 35.1583 84.4106 38.8819C84.2901 39.5499 84.3011 39.5554 83.644 39.5554L82.0614 39.5718Z" fill="white"/>
        <path d="M75.1016 39.572C74.4938 39.572 73.8805 39.572 73.2727 39.572C72.9715 39.572 72.8729 39.5063 72.9332 39.1887C74.0283 33.0229 75.1126 26.8552 76.1858 20.6857C76.2406 20.3626 76.3282 20.2476 76.6677 20.2531C77.8724 20.2531 79.0771 20.2531 80.2873 20.2531C80.5939 20.2531 80.6761 20.3188 80.6158 20.6419C80.0682 23.7467 79.5207 26.857 78.9731 29.9509C78.4255 33.0448 77.8779 36.0127 77.3796 39.0463C77.3139 39.4351 77.1934 39.5939 76.7827 39.561C76.257 39.5391 75.693 39.572 75.1016 39.572Z" fill="white"/>
        <path d="M55.1913 8.54571C55.3432 8.45652 55.4681 8.32784 55.5528 8.17335C57.3324 5.8461 59.1175 3.52433 60.8863 1.19708C60.9633 1.07229 61.0727 0.970726 61.2029 0.903223C61.3331 0.83572 61.4791 0.804805 61.6255 0.813773C63.0054 0.841152 64.3634 0.813773 65.7707 0.813773C66.05 0.813773 66.154 0.841152 66.0938 1.18066C65.2432 5.92641 64.4072 10.6813 63.5859 15.4453C63.3942 16.5405 63.1916 17.5973 63.0383 18.6815C62.9945 18.9553 62.8904 19.0156 62.6331 19.0156C61.3298 19.0156 60.0229 19.0156 58.7123 19.0156C58.3838 19.0156 58.3181 18.9444 58.3783 18.6104C58.9259 15.6424 59.4297 12.6745 59.9444 9.70659C59.9444 9.6354 59.9444 9.55874 59.9444 9.34518C59.7418 9.59707 59.6159 9.74492 59.4954 9.89277L54.6054 16.1845C54.2221 16.6774 53.91 16.639 53.68 16.086L51.0078 9.70659C50.972 9.53952 50.8814 9.38919 50.7504 9.27947L50.2685 12.0174C49.9126 14.2406 49.5019 16.4802 49.1241 18.7089C49.0803 18.9663 48.9762 19.0211 48.7462 19.0211C47.4211 19.0211 46.1014 19.0211 44.7762 19.0211C44.4039 19.0211 44.4039 18.8623 44.4477 18.583C44.7616 16.8417 45.0701 15.1003 45.3731 13.359C46.0795 9.31233 46.7968 5.30399 47.4923 1.26827C47.5525 0.912339 47.6784 0.813773 48.0399 0.819249C49.3176 0.819249 50.5953 0.819249 51.873 0.819249C51.9953 0.799764 52.1205 0.828147 52.2224 0.898505C52.3244 0.968863 52.3953 1.07579 52.4206 1.19708C53.2638 3.47505 54.129 5.74206 54.9887 8.01455C55.049 8.21715 55.1202 8.365 55.1913 8.54571Z" fill="white"/>
        <path d="M126.805 7.40676C126.932 7.09278 127.006 6.7596 127.024 6.42111C127.062 6.14731 127.15 5.99946 127.473 6.00494C128.639 6.00494 129.816 6.00494 130.983 6.00494C131.339 6.00494 131.383 6.14731 131.328 6.43753C130.649 10.2341 129.973 14.0271 129.302 17.8164C129.177 18.7086 128.949 19.5833 128.623 20.4229C128.305 21.2209 127.826 21.9447 127.215 22.5487C126.605 23.1527 125.876 23.624 125.074 23.933C122.849 24.8278 120.365 24.8395 118.131 23.9658C117.016 23.5603 116.094 22.7487 115.552 21.6933C115.431 21.4688 115.448 21.3702 115.683 21.2498C116.778 20.7022 117.874 20.0998 118.969 19.5194C119.122 19.4318 119.226 19.3825 119.363 19.5851C120.179 20.7953 121.389 20.9376 122.681 20.6803C123.245 20.5931 123.764 20.3237 124.16 19.9133C124.555 19.5028 124.805 18.974 124.872 18.4078C124.921 18.1559 124.959 17.9095 125.014 17.6247C124.82 17.6797 124.643 17.7834 124.499 17.9259C122.555 19.3387 119.21 19.0868 117.561 17.3783C117.061 16.8359 116.673 16.1992 116.421 15.5054C116.169 14.8115 116.058 14.0744 116.094 13.3371C116.055 12.0926 116.32 10.8574 116.866 9.73825C117.412 8.6191 118.222 7.64973 119.226 6.91393C119.996 6.32795 120.891 5.92851 121.841 5.74702C122.791 5.56554 123.771 5.60697 124.702 5.86804C125.566 6.10916 126.313 6.65597 126.805 7.40676ZM122.813 14.8923C123.554 14.894 124.272 14.6416 124.849 14.1772C125.426 13.7128 125.827 13.0645 125.983 12.3405C126.257 10.9935 125.578 9.89281 124.341 9.65735C123.597 9.51806 122.828 9.64327 122.167 10.0113C121.506 10.3793 120.994 10.967 120.721 11.6725C120.004 13.4467 120.978 14.8978 122.813 14.8923Z" fill="white"/>
        <path d="M74.0612 5.64895C77.7574 5.59419 80.4077 8.60045 80.0847 12.2857C79.8891 14.2504 78.9651 16.0707 77.4945 17.3883C76.024 18.7058 74.1135 19.425 72.1392 19.4043C68.2458 19.3715 65.6229 16.294 66.2088 12.439C66.4513 10.5387 67.3868 8.79479 68.836 7.54172C70.2851 6.28866 72.1458 5.61465 74.0612 5.64895ZM72.5937 15.177C73.3518 15.1623 74.079 14.8736 74.6407 14.3642C75.2024 13.8548 75.5607 13.1593 75.6492 12.4062C75.9011 10.621 74.6143 9.47659 72.862 9.92561C72.1779 10.116 71.575 10.5254 71.1458 11.0911C70.7165 11.6569 70.4846 12.3477 70.4855 13.0578C70.4662 13.3401 70.5074 13.6233 70.6064 13.8884C70.7054 14.1535 70.8598 14.3945 71.0594 14.5951C71.259 14.7957 71.4991 14.9514 71.7637 15.0517C72.0283 15.1521 72.3112 15.1948 72.5937 15.177Z" fill="white"/>
        <path d="M106.933 7.23685C107.676 6.45068 108.654 5.92624 109.72 5.74193C111.297 5.49552 112.765 5.70908 113.843 7.03424C114.205 7.49344 114.467 8.02234 114.614 8.58774C114.761 9.15313 114.789 9.74282 114.698 10.3198C114.386 13.1508 113.739 15.9325 113.296 18.7471C113.295 18.7908 113.285 18.8338 113.265 18.8731C113.246 18.9124 113.219 18.947 113.185 18.9746C113.151 19.0022 113.112 19.0222 113.069 19.033C113.027 19.0438 112.983 19.0453 112.94 19.0374C111.691 19.0374 110.443 19.0374 109.2 19.0374C108.97 19.0374 108.888 18.999 108.932 18.7362C109.329 16.5239 109.722 14.3098 110.109 12.0939C110.106 12.0794 110.106 12.0646 110.109 12.0501C110.317 10.9878 110.109 10.3198 109.479 9.98026C109.052 9.78985 108.578 9.73036 108.117 9.80931C107.656 9.88826 107.229 10.1021 106.889 10.4238C106.391 10.9716 106.08 11.6641 106.002 12.4006C105.637 14.4814 105.272 16.5641 104.907 18.6486C104.857 18.9333 104.764 19.0428 104.458 19.0428C103.253 19.0428 102.054 19.0428 100.849 19.0428C100.532 19.0428 100.493 18.9607 100.548 18.6595C101.278 14.6439 101.983 10.6136 102.662 6.56879C102.738 6.14715 102.886 6.0212 103.319 6.0212C104.414 6.05953 105.542 6.04858 106.654 6.0212C107.026 6.0212 107.141 6.13072 107.048 6.48665C107.015 6.67831 106.988 6.89734 106.933 7.23685Z" fill="white"/>
        <path d="M86.3764 13.1728C86.5462 13.0852 86.5681 12.9264 86.6338 12.7949C87.6797 10.6867 88.7256 8.57851 89.755 6.45935C89.8021 6.31363 89.8982 6.18872 90.027 6.10586C90.1558 6.023 90.3093 5.98731 90.4614 6.00485C91.7209 6.00485 92.9858 6.00485 94.2453 6.00485H94.6943C94.6947 6.20483 94.6249 6.39861 94.4972 6.55244C92.3068 10.5863 90.1292 14.6257 87.9644 18.6705C87.9142 18.7911 87.8262 18.8921 87.7136 18.9583C87.601 19.0244 87.4699 19.0522 87.3402 19.0374C86.1245 19.0374 84.9089 19.0374 83.6933 19.0374C83.4852 19.0374 83.3373 19.0374 83.2826 18.7636C82.5214 14.6513 81.7384 10.5498 80.9608 6.43197C80.906 6.1527 80.9608 6.02675 81.2893 6.02675C82.5214 6.02675 83.7535 6.02675 84.9856 6.02675C85.2484 6.02675 85.336 6.11437 85.3743 6.38269C85.6646 8.47447 85.9712 10.5663 86.2724 12.658C86.2998 12.8168 86.3381 12.9866 86.3764 13.1728Z" fill="white"/>
        <path d="M95.91 19.0155C95.3624 19.0155 94.7655 19.0155 94.2015 19.0155C93.8072 19.0155 93.7141 18.917 93.7798 18.5391C94.0974 16.8306 94.3877 15.1167 94.6888 13.4028C95.105 11.0755 95.5212 8.74279 95.9209 6.41554C95.9702 6.13079 96.0469 5.9939 96.3645 5.9939C97.5691 5.9939 98.7684 5.9939 99.9676 5.9939C100.269 5.9939 100.34 6.05413 100.285 6.37721C99.5551 10.4184 98.8468 14.4614 98.1605 18.5063C98.0894 18.906 97.947 19.0539 97.5308 19.01C97.0051 18.9827 96.4575 19.0155 95.91 19.0155Z" fill="white"/>
        <path d="M99.1954 0.0032933C99.5197 -0.0151673 99.8437 0.0441792 100.14 0.176382C100.437 0.308585 100.698 0.509812 100.901 0.763277C101.104 1.01674 101.243 1.3151 101.308 1.63345C101.372 1.95181 101.359 2.28094 101.271 2.59338C101.167 3.14496 100.904 3.65423 100.515 4.05883C100.126 4.46344 99.6275 4.7458 99.0804 4.87134C98.7039 5.0005 98.2986 5.02071 97.9111 4.92965C97.5236 4.83858 97.1696 4.63995 96.8901 4.35661C96.5993 4.0599 96.4006 3.68538 96.318 3.27825C96.2355 2.87112 96.2725 2.44878 96.4246 2.06222C96.6169 1.47468 96.9866 0.961205 97.4828 0.592479C97.9791 0.223752 98.5774 0.017911 99.1954 0.0032933Z" fill="white"/>
        </svg>
        
    
    )
}

